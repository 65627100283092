* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Circularpro book';
  src: url('https://assets.website-files.com/6347244ba8d63489ba51c08e/6347244ba8d634111651c0ca_CircularPro-Book.otf')
    format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-family: Inter, sans-serif;
}

@media screen and (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}

h1,
h2,
h3,
h4 {
  font-family: Circularpro book, Inter, sans-serif;
  font-weight: 400;
  color: #3e3935;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  color: #3e3935;
}

a {
  text-decoration: none;
  color: #3e3935;
}

.whatsapp {
  position: fixed;
  bottom: 8px;
  right: 8px;
  z-index: 888888;
  max-width: 50px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.whatsapp:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .top_mobile {
    bottom: 70px !important;
  }
}

/* black: #3E3935 */
/* blue: #00B6AD;
white: #F7F7F7;
grey: #BCBCBC;
greyDark: #7A7A7A; */
